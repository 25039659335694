import { lazy } from 'react';
import {BrowserRouter,Routes,Route} from "react-router-dom";
import './App.css';
const Index = lazy(() => import("./pages/index"));
//import Index from './pages';

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/">
              <Route index element={<Index />} />
              {/* 404 */}
              <Route path="*" element={<Index />}/>
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
